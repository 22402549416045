import React, { useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const Carousel = ({ images, videos }) => {
  const [index, setIndex] = useState (0);
  const media = [...images, ...videos];

  const incrementIndex = () => {
    if (index === media.length - 1)
      setIndex (0);
    else
      setIndex (index + 1);
  };

  const decrementIndex = () => {
    if (index === 0)
      setIndex (media.length - 1);
    else
      setIndex (index - 1);
  };

  return (
    <>
      <div className="h-full">
        {media[index].type === "image"
          ? <img src={media[index].content} className="h-96 w-full rounded-t mx-auto" alt="diamond" />
          : <video className="h-96 w-full rounded-t mx-auto" controls>
              <source src={media[index].content} />
            </video>
        }
        {media.length > 1 &&
          <>
            <BsChevronLeft
              className="absolute top-1/4 left-0 text-gray-200 scale-125 m-4 cursor-pointer"
              onClick={() => decrementIndex ()}
            />
            <BsChevronRight
              className="absolute top-1/4 right-0 text-gray-200 scale-125 m-4 cursor-pointer"
              onClick={() => incrementIndex ()}
            />
            
          </>
        }
      </div>
    </>
  );
};

export default Carousel;