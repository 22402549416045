import React from "react";
import Carousel from "components/Carousel";

const DiamondModal = ({ images, videos, name, description, price, itemNum, toggleModal, toggleContactModal }) => {
  return (
    <>
      <div className="fixed top-0 left-0 right-0 bottom-0 opacity-25 bg-black z-20" />
      <div className="fixed sm:left-1/2 sm:top-1/2 sm:transform sm:-translate-x-1/2 
                      sm:-translate-y-1/2 w-full h-screen sm:h-auto sm:w-1/2 
                      bg-white rounded-lg z-30 top-0 left-0"
      >
        {(images.length !== 0 || videos.length !== 0) &&
          <div className="h-1/2 mb-4 sm:mb-0">
            <Carousel
              images={images.map (i => ({ content: i, type: "image" }))}
              videos={videos.map (v => ({ content: v, type: "video" }))}
            />
          </div>
        }
        <div className="h-1/2 p-6">
          <p className="text-gray-400 text-right">Item No. {itemNum}</p>
          <p className="font-bold text-4xl mb-2">{name}</p>
          <p className="text-2xl text-primary-dark font-bold mb-2">${price}</p>
          <p className="text-md text-gray-600 w-1/2 mb-2">{description}</p>
          <div className="flex justify-end items-end space-x-2">
            <div
              className="w-1/4 h-10 border border-primary-dark hover:bg-primary-dark text-md
                        text-primary-dark hover:text-white rounded-md flex justify-center 
                        items-center cursor-pointer ml-auto"
              onClick={toggleModal}
            >
              CLOSE
            </div>
            <div
              className="w-1/4 h-10 border border-primary-dark hover:bg-primary-dark text-md
                        text-primary-dark hover:text-white rounded-md flex justify-center 
                        items-center cursor-pointer ml-auto"
              onClick={toggleContactModal}
            >
              CONTACT
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiamondModal;