import React from "react";
import { PuffLoader } from "react-spinners";

const LoadingOverlay = ({ loading }) => {
  return (
    <>
      <div className="fixed top-0 left-0 right-0 bottom-0 opacity-25 bg-black z-20" />
      <div className="fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30">
        <PuffLoader
          loading={loading}
          color="#779ECB"
          speedMultiplier={1}
          className="scale-[3]"
        />
      </div>
    </>
  );
};

export default LoadingOverlay;