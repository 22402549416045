import React, { useEffect, useState } from "react";
import DiamondCard from "components/DiamondCard";
import { createProduct } from "api/diamondsAPI";
import LoadingOverlay from "components/LoadingOverlay";

const Exec = () => {
  const [name, setName] = useState ("");
  const [desc, setDesc] = useState ("");
  const [price, setPrice] = useState (0);
  const [coverImg, setCoverImg] = useState (null);
  const [images, setImages] = useState ([]);
  const [videos, setVideos] = useState ([]);
  const [loading, setLoading] = useState (false);
  const [success, setSuccess] = useState (null);

  /* Error flags */
  const [coverImgErr, setCoverImgErr] = useState (false);
  const [nameErr, setNameErr] = useState (false);
  const [priceErr, setPriceErr] = useState (null);

  useEffect (() => {
    if (!window.sessionStorage.getItem ("auth"))
      window.location.pathname = "login";
  }, []);

  const onCreate = async () => {
    if (coverImg === null)
      setCoverImgErr (true);
    else
      setCoverImgErr (false);
    
    if (name === "")
      setNameErr (true);
    else
      setNameErr (false);
    
    if (price === 0)
      setPriceErr (true);
    else
      setPriceErr (false);
    
    if (!coverImgErr && !nameErr && !priceErr)
    {
      const diamond = {
        name,
        desc,
        price,
        coverImg,
        images,
        videos
      }
      setLoading (true);
      const success = await createProduct (diamond);
      onSuccess (success);
      setLoading (false);
    }
  };

  const onSuccess = success => {
    if (success)
    {
      setSuccess (true);
      setTimeout (() => setSuccess (null), 5000);
    }
    else
    {
      setSuccess (false);
      setTimeout (() => setSuccess (null), 5000);
    }
  };

  return (
    <div className="py-32 px-8 sm:px-32 sm:flex">
      {loading && <LoadingOverlay loading={loading} />}
      <div className="sm:h-1/2 w-full sm:w-1/2 bg-gray-100 rounded p-4">
      <form className="rounded px-8 py-6">
          <div className="mb-4">
            <label className="block text-gray-400 text-sm font-bold mb-2">
              Name {nameErr && <span className="text-xs text-red-500 ml-2">*Name must not be empty.</span>}
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="Name of product"
              onChange={e => setName (e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400 text-sm font-bold mb-2">
              Description
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="desc"
              placeholder="Write a description of the product."
              onChange={e => setDesc (e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400 text-sm font-bold mb-2">
              Price {priceErr && <span className="text-xs text-red-500 ml-2">*Price must not be 0.</span>}
            </label>
            <input
              className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="price"
              type="number"
              placeholder="0"
              min={0}
              step={100}
              onChange={e => setPrice (Number (e.target.value))}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400 text-sm font-bold mb-2">
              Select Cover Image {coverImgErr && <span className="text-xs text-red-500 ml-2">*Cover image must not be empty.</span>}
            </label>
            <input
              type="file"
              id="coverImg"
              name="img"
              accept="image/*"
              onChange={e => setCoverImg (e.target.files[0])}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400 text-sm font-bold mb-2">
              Select Extra Images
            </label>
            <input
              type="file"
              id="imgs"
              name="imgs"
              accept="image/*"
              multiple
              onChange={e => setImages (Array.from (e.target.files))}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400 text-sm font-bold mb-2">
              Select Videos
            </label>
            <input
              type="file"
              id="videos"
              name="videos"
              accept="video/*"
              multiple
              onChange={e => setVideos (Array.from (e.target.files))}
            />
          </div>
        </form>
        <div className="w-full flex justify-end items-center space-x-4">
          {!!success && <p className="text-xs text-green-500">SUCCESS</p>}
          {success != null && !success && <p className="text-xs text-red-500">FAILED</p>}
          <div
            className="w-20 h-8 bg-primary-dark hover:bg-primary-light text-md text-white rounded-md flex justify-center items-center cursor-pointer"
            onClick={() => onCreate ()}
          >
            Create
          </div>
        </div>
      </div>
      <div className="w-full sm:w-1/2 mt-8 sm:mt-0">
        <p className="text-2xl font-semibold mb-4 sm:mb-8 text-primary-dark text-center">Example Product Card</p> 
        <div className="flex justify-center items-center w-full">
          <DiamondCard
            name={name}
            price={price}
            primaryImg={coverImg ? URL.createObjectURL (coverImg) : null}
            images={images.map (i => URL.createObjectURL (i))}
            videos={videos.map (v => URL.createObjectURL (v))}
            desc={desc}
          />
        </div>
      </div>
    </div>
  );
};

export default Exec;