import { db } from "api/firesbase";
import { addDoc, collection } from "firebase/firestore";

export const addContact = async (name, itemNum, email, message) => {
  try
  {
    await addDoc (collection (db, "contacts"), {
      name: name,
      itemNum: itemNum,
      email: email,
      message: message
    });
  }
  catch (error)
  {
    console.error (error);
    return false;
  }
  return true;
};