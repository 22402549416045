import React, { useEffect, useState } from "react";
import { fetchDiamonds } from "api/diamondsAPI";
import DiamondCard from "components/DiamondCard";
import LoadingOverlay from "components/LoadingOverlay";

const Product = () => {
  const [loading, setLoading] = useState (false);
  const [diamonds, setDiamonds] = useState ([]);

  const fetchAll = async () => {
    setLoading (true);
    let docs = await fetchDiamonds ();
    setLoading (false);
    setDiamonds (docs);
  };

  useEffect (() => {
    fetchAll ().catch (console.error);
  }, []);

  return (
    <div className="px-12 pt-20 sm:pt-32 pb-16">
      {loading && <LoadingOverlay loading={loading} />}
      <p className="text-3xl sm:text-5xl font-bold mb-8 text-primary-dark">Diamonds</p>
      {!loading && diamonds.length === 0 && <p className="text-gray-400 text-center">No diamonds to show.</p>}
      <div className="space-y-12 sm:space-y-0 sm:grid gap-6 grid-cols-4">
        {diamonds.map (d => {
            return (
              <DiamondCard
                name={d.name}
                price={d.price}
                primaryImg={d.coverImg}
                itemNum={d.itemNum}
                images={d.images}
                videos={d.videos}
                desc={d.desc}
              />
            );
          })
        }
      </div>
    </div>
  );
};

export default Product;