import React, { useState } from "react";
import ContactModal from "components/ContactModal";
import { MdDiamond } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { HiX } from "react-icons/hi";

const Nav = () => {
  const [modalOpen, setModalOpen] = useState (false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState (false);

  const toggleModal = () => {
    setModalOpen (!modalOpen);
    setMobileMenuOpen (false);
  };

  const setPath = pathname => {
    if (`/${pathname}` !== window.location.pathname)
      window.location.pathname = pathname;
    setMobileMenuOpen (false);
  };

  return (
    <>
      <div className="block sm:hidden fixed top-0 w-screen py-6 px-8 bg-white z-10">
        <MdDiamond className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-8 w-8 text-secondary" />
        <div className="flex wi-full justify-end">
          {!mobileMenuOpen
          ? <RxHamburgerMenu
              className="text-primary-dark scale-[2]"
              onClick={() => setMobileMenuOpen (true)}
            /> 
          : <HiX
              className="text-primary-dark scale-[2]"
              onClick={() => setMobileMenuOpen (false)}
            /> 
          }
        </div>
        {mobileMenuOpen &&
          <div className="fixed left-0 right-0 mt-6 w-screen bg-gray-100 text-center py-4 rounded-b-md">
            <p
              className="text-gray-400 hover:text-primary-dark cursor-pointer mb-2 text-lg"
              onClick={() => setPath ("")}
            >
              Home
            </p>
            <p
              className="text-gray-400 hover:text-primary-dark cursor-pointer mb-6 text-lg"
              onClick={() => setPath ("diamonds")}
            >
              Diamonds
            </p>
            <div
              className="w-28 h-8 bg-primary-dark hover:bg-primary-light
                        text-white rounded-md flex justify-center items-center
                        cursor-pointer mx-auto"
              onClick={toggleModal}
            >
              Contact Me!
            </div>
          </div>
        }
      </div>
      <div className="hidden sm:block fixed top-0 w-screen px-12 py-6 bg-white z-10">
        <MdDiamond className="absolute m-2 h-8 w-8 text-secondary" />
        <div className="flex w-full justify-end space-x-14 text-lg items-center">
          <p
            className="text-gray-400 hover:text-primary-dark cursor-pointer"
            onClick={() => setPath ("")}
          >
            Home
          </p>
          <p
            className="text-gray-400 hover:text-primary-dark cursor-pointer"
            onClick={() => setPath ("diamonds")}
          >
            Diamonds
          </p>
          <div
            className="w-36 h-12 bg-primary-dark hover:bg-primary-light text-lg
                      text-white rounded-md flex justify-center items-center cursor-pointer"
            onClick={toggleModal}
          >
            Contact Me!
          </div>
        </div>
      </div>
      {modalOpen && <ContactModal itemNum={null} closeModal={toggleModal} />}
    </>
  );
};

export default Nav;