import React, { useState, useEffect } from "react";
import { login } from "api/authAPI";

const Login = () => {
  const [email, setEmail] = useState ("");
  const [password, setPassword] = useState ("");

  const onLogin = async () => {
    if (await login (email, password))
    {
      window.sessionStorage.setItem ("auth", "valid");
      window.location.pathname = "exec";
    }
  };

  useEffect (() => {
    if (window.sessionStorage.getItem ("auth"))
      window.location.pathname = "exec";
  }, []);

  return (
    <div className="px-8 sm:px-16 h-screen flex justify-center items-center">
      <div className="w-full sm:w-1/3 bg-gray-100 rounded-md px-8 py-6">
        <form className="bg-gray-100 rounded px-4 sm:px-8 py-6">
          <div className="mb-4">
            <label className="block text-gray-400 text-sm font-bold mb-2">
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="text"
              placeholder="Email"
              onChange={e => setEmail (e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400 text-sm font-bold mb-2">
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Password"
              onChange={e => setPassword (e.target.value)}
            />
          </div>
        </form>
        <div className="w-full">
          <div
            className="w-20 h-8 bg-primary-dark hover:bg-primary-light text-md text-white rounded-md flex justify-center items-center cursor-pointer ml-auto"
            onClick={() => onLogin ()}
          >
            Login
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;