import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

const storage = getStorage ();

export const uploadImg = async (img) => {
  var url;
  try
  {
    const imgRef = storageRef (storage, `images/${img.name}`);
    const res = await uploadBytes (imgRef, img);
    url = await getDownloadURL (res.ref);
  }
  catch (error)
  {
    console.error (error);
    return null;
  }
  return url;
};

export const uploadVid = async (vid) => {
  var url;
  try
  {
    const vidRef = storageRef (storage, `videos/${vid.name}`);
    const res = await uploadBytes (vidRef, vid);
    url = await getDownloadURL (res.ref);
  }
  catch (error)
  {
    console.error (error);
    return null;
  }
  return url;
};