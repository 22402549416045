import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

/* Page imports */
import Landing from "pages/Landing";
import Product from "pages/Product";
import Login from "pages/Login";
import Exec from "pages/Exec";

/* Component imports */
import Nav from "components/Nav";

const App = () => {
  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route index element={<Landing />} />
        <Route path="diamonds" element={<Product />} />
        <Route path="login" element={<Login />} />
        <Route path="exec" element={<Exec />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
