import React from "react";
import Headshot from "assets/headshot.jpeg";
import { MdDiamond } from "react-icons/md";

const Landing = () => {
  return (
    <>
      <MdDiamond className="z-[-1] opacity-20 text-primary-light sm:text-secondary h-1/2 w-full absolute top-1/3 sm:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
      <div className="px-6 sm:px-12 pt-28 sm:pt-40">
        <p className="sm:font-extrabold text-7xl text-transparent bg-gradient-to-r from-primary-dark to-secondary bg-clip-text mb-12 sm:mb-24 sm:text-left text-center">Direct Diamond Dealer</p>
        <div className="sm:flex sm:justify-center mb-6 sm:mb-12">
          <img src={Headshot} alt="Todd Johnson" className="mx-auto h-72 rounded-full mb-8 sm:mb-0" />
          <p className="text-xl text-gray-600 leading-9 text-center sm:text-left sm:px-32">
            Hi, I'm Todd Johnson.
            I deal with diamond cutters based in Israel to provide you with the 
            best quality diamonds at the lowest price. The diamonds are acquired 
            before entering the diamond market and before being put on sale to distributors. 
            These distributors typically sell to resellers and jewelry stores, making 
            the price increase at each step of the way. Because I collect diamonds 
            directly from the source, I can provide you with diamonds at the price 
            of what the retailers buy them for. Basically, my diamond prices are a 
            third of what you would find at your other stores. In addition to selling 
            diamonds, I buy your diamonds too! For all your diamond needs, call or text 
            (317) 590-8683, or fill out the contact form if you prefer email.
          </p>
        </div>
        <div className="flex justify-center p-6 sm:p-12">
          <div
            className="w-96 h-12 border border-primary-dark hover:bg-primary-dark text-lg
                      text-primary-dark hover:text-white rounded-md flex justify-center 
                      items-center cursor-pointer"
            onClick={() => window.location.pathname = "diamonds"}
          >
            See my diamonds
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;