import React, { useState, useEffect } from "react";
import { HiX } from "react-icons/hi";
import { TbDiamond } from "react-icons/tb";
import { fetchAllItemNums } from "api/diamondsAPI";
import { addContact } from "api/contactsAPI";

const ContactModal = ({ itemNum, closeModal }) => {
  const [name, setName] = useState ("");
  const [email, setEmail] = useState ("");
  const [item, setItem] = useState (itemNum);
  const [message, setMessage] = useState ("");
  const [sent, setSent] = useState (false);
  const [items, setItems] = useState ([]);

  const sendMessage = async () => {
    setSent (await addContact (name, Number (item), email, message));
  };

  const setItemNums = async () => {
    setItems (await fetchAllItemNums ());
  };

  useEffect (() => {
    setItemNums ();
  }, []);

  return (
    <>
      <div className="fixed top-0 left-0 right-0 bottom-0 opacity-25 bg-black z-20" />
      <div 
        className="fixed sm:left-1/2 sm:top-1/2 sm:transform sm:-translate-x-1/2
                  sm:-translate-y-1/2 w-full sm:w-1/2 h-full sm:h-auto bg-white 
                  rounded-lg p-6 sm:p-12 z-30 top-0 left-0"
      >
        <div className="w-full flex justify-end">
          <HiX className="h-6 w-6 text-gray-400 cursor-pointer" onClick={closeModal} />
        </div>
        {sent
        ? <div className="w-full h-full text-center pt-24">
            <TbDiamond className="mx-auto mb-6 h-24 w-24 text-primary-dark"/>
            <p className="text-xl font-semibold">
              Thanks for the inquiry! Todd will get back to you as soon as possible.
            </p>
          </div>
        : <div>
            <form className="bg-white rounded px-8 py-6">
              <div className="mb-4">
                <label className="block text-gray-400 text-sm font-bold mb-2">
                  Full Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  placeholder="John Doe"
                  onChange={(e) => setName (e.target.value)}
                />
              </div>
              <div className="mb-4 sm:flex">
                <div className="w-full sm:w-1/2 sm:mr-8 mb-4 sm:mb-0">
                  <label className="block text-gray-400 text-sm font-bold mb-2">
                    Email
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="text"
                    placeholder="person@example.com"
                    onChange={(e) => setEmail (e.target.value)}
                  />
                </div>
                <div className="w-1/2">
                  <label className="block text-gray-400 text-sm font-bold mb-2">
                    Item No.
                  </label>
                  <div className="relative">
                    <select
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:shadow-outline"
                      value={item}
                      onChange={(e) => setItem (e.target.value)}
                    >
                      <option />
                      {items.map (i => {
                        return <option>{i}</option>
                      })}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-400 text-sm font-bold mb-2">
                  Message
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full h-40 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="message"
                  placeholder="Write a direct message or inquiry to Todd."
                  onChange={(e) => setMessage (e.target.value)}
                />
              </div>
            </form>
            <div className="w-full">
              <div
                className="w-20 h-8 bg-primary-dark hover:bg-primary-light text-md text-white rounded-md flex justify-center items-center cursor-pointer ml-auto"
                onClick={sendMessage}
              >
                Send
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default ContactModal;