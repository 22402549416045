import { db } from "api/firesbase";
import { collection, getDocs, query, addDoc, orderBy, limit, deleteDoc, where, doc } from "firebase/firestore";
import { uploadImg, uploadVid } from "api/storageAPI";

export const fetchDiamonds = async () => {
  const q = query (collection (db, "diamonds"), orderBy ("itemNum"));
  const qSnapshot = await getDocs (q);
  const docs = [];
  qSnapshot.forEach (doc => {
    const data = doc.data ();
    data["id"] = doc.id;
    docs.push (data);
  });
  return docs;
};

export const createProduct = async diamond => {
  try
  {
    /* Upload cover image. */
    const coverImgPath = await uploadImg (diamond.coverImg);
    if (coverImgPath === null)
      return false;

    /* Upload extra images. */
    const images = [];
    for (var i = 0; i < diamond.images.length; i++)
    {
      const imagePath = await uploadImg (diamond.images[i]);
      if (imagePath !== null)
        images.push (imagePath);
    }

    /* Upload extra videos. */
    const videos = [];
    for (var j = 0; j < diamond.videos.length; j++)
    {
      const vidPath = await uploadVid (diamond.videos[j]);
      if (vidPath !== null)
        videos.push (vidPath);
    }

    await addDoc (collection (db, "diamonds"), {
      name: diamond.name,
      desc: diamond.desc,
      price: diamond.price,
      coverImg: coverImgPath,
      itemNum: await getNextItemNum (),
      images: images,
      videos: videos
    });
  }
  catch (error)
  {
    console.error (error);
    return false;
  }
  return true;
};

export const fetchAllItemNums = async () => {
  var ret = [];
  try
  {
    const diamonds = await fetchDiamonds ();
    ret = diamonds.map (d => d.itemNum);
  }
  catch (error)
  {
    console.error (error);
    return null;
  }
  return ret;
};

export const deleteItem = async itemNum => {
  try
  {
    const q = query (collection (db, "diamonds"), where ("itemNum", "==", Number (itemNum)), limit (1));
    const qSnapshot = await getDocs (q);
    const document = qSnapshot.docs[0];
    await deleteDoc (doc (db, "diamonds", document.id));
  }
  catch (error)
  {
    console.error (error);
  }
};

const getNextItemNum = async () => {
  var nextItemNum = 0;
  try
  {
    const q = query (collection (db, "diamonds"), orderBy ("itemNum", "desc"), limit (1));
    const qSnapshot = await getDocs (q);
    qSnapshot.forEach (doc => {
      const data = doc.data ();
      nextItemNum = Number (data["itemNum"]) + 1;
    });
  }
  catch (error)
  {
    console.error (error);
    return null;
  }
  return nextItemNum;
};
