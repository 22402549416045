/* Firebase hosting imports. */
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

/* Firebase configuration for Direct Diamond Dealer. */
const firebaseConfig = {
  apiKey: "AIzaSyDbZ-WjUJW5e1sd9rE1atN81oAAZqxw8_c",
  authDomain: "direct-diamond-dealer.firebaseapp.com",
  projectId: "direct-diamond-dealer",
  storageBucket: "direct-diamond-dealer.appspot.com",
  messagingSenderId: "780756385738",
  appId: "1:780756385738:web:1a9cfc8e45fd87d9af1933",
  measurementId: "G-G38QE58X2S"
};

/* Firebase initialization. */
const app = initializeApp (firebaseConfig);

export const db = getFirestore (app);