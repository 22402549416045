import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const auth = getAuth ();

export const login = async (email, password) => {
  try
  {
    await signInWithEmailAndPassword (auth, email, password);
  }
  catch (error)
  {
    console.error (error);
    return false;
  }
  return true;
};

export const authenticated = () => {
  const auth = getAuth ();
  const user = auth.currentUser;

  if (user)
    return true;
  
  return false;
};
