import React, { useState } from "react";
import DefaultImg from "assets/default-image.jpeg";
import DiamondModal from "components/DiamondModal";
import ContactModal from "components/ContactModal";
import { BsTrash3Fill } from "react-icons/bs";
import { authenticated } from "api/authAPI";
import { deleteItem } from "api/diamondsAPI";

const DiamondCard = ({ name, primaryImg, price, itemNum = 0, images, videos, desc }) => {
  const [diamondModalOpen, setDiamondModalOpen] = useState (false);
  const [contactModalOpen, setContactModalOpen] = useState (false);

  const toggleDiamondModal = () => {
    setDiamondModalOpen (!diamondModalOpen);
  };

  const toggleContactModal = () => {
    setDiamondModalOpen (false);
    setContactModalOpen (!contactModalOpen);
  };

  const deleteDiamond = async () => {
    await deleteItem (itemNum);
    window.location.reload ();
  };

  return (
    <>
      {diamondModalOpen &&
        <DiamondModal
          name={name}
          images={[primaryImg, ...images]}
          description={desc}
          videos={videos}
          itemNum={itemNum}
          price={price}
          toggleModal={toggleDiamondModal}
          toggleContactModal={toggleContactModal}
        />
      }
      {contactModalOpen &&
        <ContactModal itemNum={itemNum} closeModal={toggleContactModal} />
      }
      <div className="w-80 h-88 rounded-lg drop-shadow-lg bg-gray-100 z-0">
        {primaryImg == null
        ? <img
            src={DefaultImg}
            className="rounded-t-lg h-64 w-full"
            alt="Default"
          />
        : <img
            src={primaryImg}
            className="rounded-t-lg h-64 w-full"
            alt="Diamond"
          />
        }
        <div className="p-6 space-y-4">
          <div className="flex">
            <p className="text-2xl font-bold mr-auto">{name}</p>
            <p className="text-sm">Item No. {itemNum}</p>
          </div>
          <p className="text-xl">${price}</p>
          <div className="flex items-center">
            {authenticated () &&
              <BsTrash3Fill
                className="scale-125 text-red-400 cursor-pointer"
                onClick={deleteDiamond}
              />
            }
            <div
              className="w-1/2 h-10 border border-primary-dark hover:bg-primary-dark text-md
                        text-primary-dark hover:text-white rounded-md flex justify-center 
                        items-center cursor-pointer ml-auto"
              onClick={toggleDiamondModal}
            >
              DETAILS
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DiamondCard;